import React,{Suspense, useState, Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {BrowserRouter as Router,Route,Redirect} from 'react-router-dom'
import Header from './modules/Header'
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {Typography} from '@material-ui/core'
import  {APP_URL} from './config.json';

import {pathSpliter,authInfoSeter,accessChecker} from './lib/functions'
import {currentRouteSet,authInfoSet} from './actions/actions'; 

const SignIn = React.lazy(() => import('./modules/signin'));
const Dashboard = React.lazy(() => import('./modules/Dashboard'));
const ModuleDetail = React.lazy(() => import('./modules/ModuleDetail'));
const UsersManage = React.lazy(() => import('./modules/settings/UsersManage'));
const warehousesManage = React.lazy(() => import('./modules/settings/warehouses_manage'));
const BranchesManage = React.lazy(() => import('./modules/settings/branches_manage'));
const InstitutionProfile = React.lazy(() => import('./modules/settings/institution_profile')); 
const UnitManage = React.lazy(() => import('./modules/settings/unit_manage')); 
const GroupManage = React.lazy(() => import('./modules/settings/group_manage')); 
const CategoryManage = React.lazy(() => import('./modules/settings/category_manage')); 
const ItemManage = React.lazy(() => import('./modules/settings/item_manage')); 
const AccountManage = React.lazy(() => import('./modules/settings/account_manage')); 
const smsSender = React.lazy(() => import('./modules/settings/sms_sender')); 
const mobileSmsSender = React.lazy(() => import('./modules/settings/mobile_sms_sender')); 

const LocationManage = React.lazy(() => import('./modules/settings/location_manage')); 
const JournalEntry = React.lazy(() => import('./modules/accounts/journal_entry')); 
const ExpenseEntry = React.lazy(() => import('./modules/accounts/expense_entry')); 
const IncomeEntry = React.lazy(() => import('./modules/accounts/income_entry')); 
const ContraEntry = React.lazy(() => import('./modules/accounts/contra_entry')); 
const CreditorPaymentEntry = React.lazy(() => import('./modules/accounts/creditor_payment_entry')); 
const DebitorReceiptEntry = React.lazy(() => import('./modules/accounts/debitor_receipt_entry')); 
const PurchaseOrderEntry = React.lazy(() => import('./modules/purchase/purchase_order_entry')); 
const PurchaseEntry = React.lazy(() => import('./modules/purchase/purchase_entry')); 
const SalesOrderEntry = React.lazy(() => import('./modules/sales/sales_order_entry')); 
const PurchaseReturnEntry = React.lazy(() => import('./modules/purchase/purchase_return_entry')); 
const ViewPurchaseOrderVoucher = React.lazy(() => import('./modules/purchase/view_purchase_order_voucher')); 
const ViewPurchaseVoucher = React.lazy(() => import('./modules/purchase/view_purchase_voucher')); 
const QuickPurchaseOrderVoucher = React.lazy(() => import('./modules/purchase/quick_purchase_order_voucher')); 
const ViewSalesOrderVoucher = React.lazy(() => import('./modules/sales/view_sales_order_voucher')); 
const ViewSalesVoucher = React.lazy(() => import('./modules/sales/view_sales_voucher')); 
const QuickPurchaseVoucher = React.lazy(() => import('./modules/purchase/quick_purchase_voucher')); 
const PurchaseOrderRecord = React.lazy(() => import('./modules/purchase/purchase_order_record')); 
const SalesOrderRecord = React.lazy(() => import('./modules/sales/sales_order_record')); 
const PurchaseRecord = React.lazy(() => import('./modules/purchase/purchase_record')); 
const SalesRecord = React.lazy(() => import('./modules/sales/sales_record')); 
const PurchaseReturnRecord = React.lazy(() => import('./modules/purchase/purchase_return_record')); 
const ViewPurchaseReturnVoucher = React.lazy(() => import('./modules/purchase/view_purchase_return_voucher')); 
const QuickPurchaseReturnVoucher = React.lazy(() => import('./modules/purchase/quick_purchase_return_voucher')); 
const QuickSalesOrderVoucher = React.lazy(() => import('./modules/sales/quick_sales_order_voucher')); 
const QuickSalesVoucher = React.lazy(() => import('./modules/sales/quick_sales_voucher')); 
const QuickSalesReturnVoucher = React.lazy(() => import('./modules/sales/quick_sales_return_voucher')); 
const SalesEntry = React.lazy(() => import('./modules/sales/sales_entry')); 
const SalesReturnEntry = React.lazy(() => import('./modules/sales/sales_return_entry')); 
const SalesReturnRecord = React.lazy(() => import('./modules/sales/sales_return_record')); 
const ViewSalesReturnVoucher = React.lazy(() => import('./modules/sales/view_sales_return_voucher')); 
const TransferEntry = React.lazy(() => import('./modules/inventory/transfer_entry')); 
const TransferRecord = React.lazy(() => import('./modules/inventory/transfer_record')); 
const TransferPendingRecord = React.lazy(() => import('./modules/inventory/transfer_pending_record')); 
const TransferReceiveRecord = React.lazy(() => import('./modules/inventory/transfer_receive_record')); 
const ViewTransferVoucher = React.lazy(() => import('./modules/inventory/transfer_voucher')); 
const ViewQuickTransferVoucher = React.lazy(() => import('./modules/inventory/quick_transfer_voucher')); 
const ManufacturingJournalEntry = React.lazy(() => import('./modules/manufacturing/manufacturing_journal_entry')); 
const viewManufacturingVoucher = React.lazy(() => import('./modules/manufacturing/view_manufacturing_voucher')); 
const quickManufacturingVoucher = React.lazy(() => import('./modules/manufacturing/quick_manufacturing_voucher')); 
const ManufacturingRecord = React.lazy(() => import('./modules/manufacturing/manufacturing_record')); 
const EmployeeEntry = React.lazy(() => import('./modules/hrpayroll/employee_entry')); 
const DepartmentEntry = React.lazy(() => import('./modules/hrpayroll/department_entry')); 
const DesignationEntry = React.lazy(() => import('./modules/hrpayroll/designation_entry')); 
const SalaryPaymentEntry = React.lazy(() => import('./modules/hrpayroll/salary_payment_entry')); 
const ServiceEntry = React.lazy(() => import('./modules/service/service_entry')); 
const viewServiceVouchers = React.lazy(() => import('./modules/service/view_service_voucher')); 
const quickServiceVoucher = React.lazy(() => import('./modules/service/quick_service_voucher')); 
const ServiceRecord = React.lazy(() => import('./modules/service/service_record')); 
const ServiceExpenseEntry = React.lazy(() => import('./modules/service/service_expense_entry')); 
const viewServiceExpenseVoucher = React.lazy(() => import('./modules/service/view_service_expense_voucher')); 
const ServiceExpenseRecord = React.lazy(() => import('./modules/service/service_expense_record')); 
const quickServiceExpenseVoucher = React.lazy(() => import('./modules/service/quick_service_expense_voucher')); 
const CustomerBalance = React.lazy(() => import('./modules/reports/debitors_balance')); 
const CreditorsBalance = React.lazy(() => import('./modules/reports/creditors_balance')); 
const CustomerBalanceLedger = React.lazy(() => import('./modules/reports/debitor_balance_ledger')); 
const CreditorBalanceLedger = React.lazy(() => import('./modules/reports/creditor_balance_ledger')); 
const AccountBalance = React.lazy(() => import('./modules/reports/account_balance')); 
const AccountLedger = React.lazy(() => import('./modules/reports/account_ledger')); 
const CapitalsBalance = React.lazy(() => import('./modules/reports/capitals_balance')); 
const CapitalLedger = React.lazy(() => import('./modules/reports/capital_ledger')); 
const IndirectExpensesBalance = React.lazy(() => import('./modules/reports/indirect_expenses_balance')); 
const IndirectIncomeBalance = React.lazy(() => import('./modules/reports/indirect_income_balance')); 
const IndirectExpenseLedger = React.lazy(() => import('./modules/reports/indirect_expense_ledger')); 
const IndirectIncomeLedger = React.lazy(() => import('./modules/reports/indirect_income_ledger')); 
const SalesLedger = React.lazy(() => import('./modules/reports/sales_ledger')); 
const SalesReturnLedger = React.lazy(() => import('./modules/reports/sales_return_ledger')); 
const PurchaseLedger = React.lazy(() => import('./modules/reports/purchase_ledger')); 
const PurchaseReturnLedger = React.lazy(() => import('./modules/reports/purchase_return_ledger')); 
const ServiceLedger = React.lazy(() => import('./modules/reports/service_ledger')); 
const ServiceExpenseLedger = React.lazy(() => import('./modules/reports/service_expense_ledger')); 
const TaxLedger = React.lazy(() => import('./modules/reports/tax_ledger')); 
const DirectExpenseBalance = React.lazy(() => import('./modules/reports/direct_expense_balance')); 
const DirectExpenseLedger = React.lazy(() => import('./modules/reports/direct_expense_ledger')); 
const DirectIncomeBalance = React.lazy(() => import('./modules/reports/direct_income_balance')); 
const DirectIncomeLedger = React.lazy(() => import('./modules/reports/direct_income_ledger')); 
const BalanceSheet = React.lazy(() => import('./modules/reports/balance_sheet')); 
const ProfitLoss = React.lazy(() => import('./modules/reports/profit_loss')); 
const ItemStock = React.lazy(() => import('./modules/inventory/item_stock')); 
const ItemLedger = React.lazy(() => import('./modules/inventory/item_ledger')); 
const loanBalance = React.lazy(() => import('./modules/reports/loan_balance')); 
const fixedAssetBalance = React.lazy(() => import('./modules/reports/fixed_asset_balance')); 
const loanLedger = React.lazy(() => import('./modules/reports/loan_ledger')); 
const fixedAssetLedger = React.lazy(() => import('./modules/reports/fixed_asset_ledger')); 
const trialBalance = React.lazy(() => import('./modules/reports/trial_balance')); 
const itemAdjustment = React.lazy(() => import('./modules/inventory/item_adjustment')); 
const AdjustmentRecord = React.lazy(() => import('./modules/inventory/adjustment_record')); 
const QuickDebitorReceipt = React.lazy(() => import('./modules/accounts/quick_debitor_receipt')); 
const UserAccess = React.lazy(() => import('./modules/user_access')); 
const DebtorReceiptRecord = React.lazy(() => import('./modules/reports/debtor_receipt_record')); 
const CreditorPaymentRecord = React.lazy(() => import('./modules/reports/creditor_payment_record')); 
const ExpenseRecord = React.lazy(() => import('./modules/reports/expense_record')); 
const IncomeRecord = React.lazy(() => import('./modules/reports/income_record')); 
const JournalRecord = React.lazy(() => import('./modules/reports/journal_record')); 
const ContraRecord = React.lazy(() => import('./modules/reports/contra_record')); 
const SalaryReport = React.lazy(() => import('./modules/reports/salary_report')); 
const AttendanceEntry = React.lazy(() => import('./modules/hrpayroll/attendance_entry')); 
const monthlySalaryReport = React.lazy(() => import('./modules/reports/monthly_salary_report')); 
const viewSalaryVoucher = React.lazy(() => import('./modules/hrpayroll/view_salary_voucher')); 
const itemBarcode = React.lazy(() => import('./modules/settings/item_barcode')); 
const quickChallanVoucher = React.lazy(() => import('./modules/sales/quick_challan_voucher')); 
const vehicleEntry = React.lazy(() => import('./modules/documents/vehicle_entry')); 
const typeEntry = React.lazy(() => import('./modules/documents/document_type_entry')); 
const beforeEntry = React.lazy(() => import('./modules/documents/before_entry')); 
const documentRenew = React.lazy(() => import('./modules/documents/document_renew')); 
const feeEntry = React.lazy(() => import('./modules/documents/fee_entry')); 




const drawerWidth = 180;
const useStyles = makeStyles((theme)=>({
  rootApp:{
    backgroundColor:'linear-gradient(to bottom, #33ccff 0%, #ff99cc 100%);'
  },
  navTopSection:{
    height:'7px',
    width:'100%',
    background: '#9CE1E7'
  },
  navTopSection:{
    height:'7px',
    background: '#9CE1E7'
  },
root: {
display: 'flex',
},
toolbarspace:{
display: 'flex',
alignItems: 'center',
justifyContent: 'flex-end',
padding: theme.spacing(0, 1),
...theme.mixins.toolbar,
},
content: {
flexGrow: 1,
padding: theme.spacing(3),
}
}))


const App = ({authInfo,currentRouteSet,currentRoute,authInfoSet})=>{
    useEffect(()=>{
      currentRouteSet(pathSpliter(window.location.pathname,1))
      authInfoSet(authInfoSeter);
    },[]);


    let classes = useStyles(); 
    
  return (
        <Router> 
        {
          authInfo!=null?
          (
          <Fragment>
           
         <div className={classes.navTopSection}></div>
         <div className={classes.rootApp} >
         <div className={classes.root}>
         <Header/> 

         <main className={classes.content} >
          <div className={classes.toolbarspace}/> 
          <Suspense fallback={<b>Loading...</b>}> 
             <Route exact path="/" component={Dashboard} />

             <Route exact path="/documents"  component={ModuleDetail} />
             <Route exact path="/settings"  component={ModuleDetail} />
             <Route exact path="/accounts"  component={ModuleDetail} />
             <Route exact path="/hrpayroll"  component={ModuleDetail} />
             <Route exact path="/manufacturing"  component={ModuleDetail} />
             <Route exact path="/purchase"  component={ModuleDetail} />
             <Route exact path="/inventory"  component={ModuleDetail} />
             <Route exact path="/order"  component={ModuleDetail} />
             <Route exact path="/sales"  component={ModuleDetail} />
             <Route exact path="/service"  component={ModuleDetail} />
             <Route exact path="/reports"  component={ModuleDetail} />

             {/* Purchase start*/}
             
             <Route exact path="/documents/document-renew"  component={documentRenew} />
             <Route exact path="/documents/fee-entry"  component={feeEntry} />
             <Route exact path="/documents/before-entry"  component={beforeEntry} />
             <Route exact path="/documents/vehicle-entry"  component={vehicleEntry} />
             <Route exact path="/documents/type-entry"  component={typeEntry} />
             <Route exact path="/reports/journal-record"  component={JournalRecord} />
             <Route exact path="/reports/contra-record"  component={ContraRecord} />
             <Route exact path="/reports/balance-sheet"  component={BalanceSheet} />
             <Route exact path="/reports/debitors-balance"  component={CustomerBalance} />
             <Route exact path="/reports/creditors-balance"  component={CreditorsBalance} />
             <Route exact path="/reports/debitor-balance-ledger"  component={CustomerBalanceLedger} />
             <Route exact path="/reports/creditor-balance-ledger"  component={CreditorBalanceLedger} />
             <Route exact path="/reports/account-balance"  component={AccountBalance} />
             <Route exact path="/reports/account-ledger"  component={AccountLedger} />
             <Route exact path="/reports/capitals-balance"  component={CapitalsBalance} />
             <Route exact path="/reports/capital-ledger"  component={CapitalLedger} />
             <Route exact path="/reports/indirect-expenses-balance"  component={IndirectExpensesBalance} />
             <Route exact path="/reports/indirect-income-balance"  component={IndirectIncomeBalance} />
             <Route exact path="/reports/indirect-expense-ledger"  component={IndirectExpenseLedger} />
             <Route exact path="/reports/indirect-income-ledger"  component={IndirectIncomeLedger} />
             <Route exact path="/reports/sales-ledger"  component={SalesLedger} />
             <Route exact path="/reports/sales-return-ledger"  component={SalesReturnLedger} />
             <Route exact path="/reports/purchase-ledger"  component={PurchaseLedger} />
             <Route exact path="/reports/purchase-return-ledger"  component={PurchaseReturnLedger} />
             <Route exact path="/reports/service-ledger"  component={ServiceLedger} />
             <Route exact path="/reports/service-expense-ledger"  component={ServiceExpenseLedger} />
             <Route exact path="/reports/tax-ledger"  component={TaxLedger} />
             <Route exact path="/reports/direct-expense-balance"  component={DirectExpenseBalance} />
             <Route exact path="/reports/direct-expense-ledger"  component={DirectExpenseLedger} />
             <Route exact path="/reports/direct-income-balance"  component={DirectIncomeBalance} />
             <Route exact path="/reports/direct-income-ledger"  component={DirectIncomeLedger} />
             <Route exact path="/reports/profit-loss"  component={ProfitLoss} />
             <Route exact path="/reports/item-stock-report"  component={ItemStock} />
             <Route exact path="/inventory/item-stock-report"  component={ItemStock} />
             <Route exact path="/inventory/item-ledger"  component={ItemLedger} />
             <Route exact path="/reports/loan-balance"  component={loanBalance} />
             <Route exact path="/reports/fixed-asset-balance"  component={fixedAssetBalance} />
             <Route exact path="/reports/loan-ledger"  component={loanLedger} />
             <Route exact path="/reports/fixed-asset-ledger"  component={fixedAssetLedger} />
             <Route exact path="/reports/trial-balance"  component={trialBalance} />
             <Route exact path="/inventory/item-adjustment"  component={itemAdjustment} />
             <Route exact path="/inventory/item-adjustment/:id"  component={itemAdjustment} />
             <Route exact path="/inventory/adjustment-record"  component={AdjustmentRecord} />
             <Route exact path="/settings/user-access/:id"  component={UserAccess} />

             <Route exact path="/accounts/quick-debitor-receipt/:id"  component={QuickDebitorReceipt} />
             <Route exact path="/reports/salary-report"  component={SalaryReport} />
             <Route exact path="/hrpayroll/salary-report"  component={SalaryReport} />
             <Route exact path="/sales/quick-challan-voucher/:id"  component={quickChallanVoucher} />

             
             

             
             

             <Route exact path="/sales/sales-entry"  component={SalesEntry} />
             <Route exact path="/manufacturing/manufacturing-journal-entry"  component={ManufacturingJournalEntry} />
             <Route exact path="/manufacturing/manufacturing-journal-update/:id"  component={ManufacturingJournalEntry} />
             <Route exact path="/manufacturing/view-manufacturing-voucher"  component={viewManufacturingVoucher} />
             <Route exact path="/manufacturing/quick-manufacturing-voucher/:id"  component={quickManufacturingVoucher} />
             <Route exact path="/manufacturing/manufacturing-record"  component={ManufacturingRecord} />
             <Route exact path="/reports/manufacturing-record"  component={ManufacturingRecord} />
             <Route exact path="/hrpayroll/attendance-entry"  component={AttendanceEntry} />
             <Route exact path="/reports/monthly-salary-report"  component={monthlySalaryReport} />
             <Route exact path="/hrpayroll/monthly-salary-report"  component={monthlySalaryReport} />
             <Route exact path="/hrpayroll/salary-voucher/:id/:id/:id"  component={viewSalaryVoucher} />
             <Route exact path="/settings/item-barcode/:id"  component={itemBarcode} />
             
             
             <Route exact path="/hrpayroll/salary-payment-entry"  component={SalaryPaymentEntry} />
             <Route exact path="/hrpayroll/employee-entry"  component={EmployeeEntry} />
             <Route exact path="/hrpayroll/department-entry"  component={DepartmentEntry} />
             <Route exact path="/hrpayroll/designation-entry"  component={DesignationEntry} />
             <Route exact path="/purchase/purchase-entry"  component={PurchaseEntry} />
             <Route exact path="/order/sales-order-entry"  component={SalesOrderEntry} />
             <Route exact path="/order/sales-order-update/:id"  component={SalesOrderEntry} />
             <Route exact path="/sales/sales-update/:id"  component={SalesEntry} />
             <Route exact path="/purchase/purchase-return-entry"  component={PurchaseReturnEntry} />
             <Route exact path="/purchase/purchase-return-record"  component={PurchaseReturnRecord} />
             <Route exact path="/reports/purchase-return-record"  component={PurchaseReturnRecord} />
             
             <Route exact path="/sales/sales-return-record"  component={SalesReturnRecord} />
             <Route exact path="/purchase/order-to-purchase/:id"  component={PurchaseEntry} />
             <Route exact path="/sales/order-to-sales/:id"  component={SalesEntry} />
             <Route exact path="/order/purchase-order-entry"  component={PurchaseOrderEntry} />
             <Route exact path="/order/purchase-order-update/:id"  component={PurchaseOrderEntry} />
             <Route exact path="/purchase/purchase-update/:id"  component={PurchaseEntry} />
             <Route exact path="/purchase/purchase-return-update/:id"  component={PurchaseReturnEntry} />
             <Route exact path="/sales/sales-return-update/:id"  component={SalesReturnEntry} />
             <Route exact path="/purchase/view-purchase-voucher"  component={ViewPurchaseVoucher} />
             <Route exact path="/sales/view-sales-voucher"  component={ViewSalesVoucher} />
             <Route exact path="/purchase/view-purchase-return-voucher"  component={ViewPurchaseReturnVoucher} />
             <Route exact path="/sales/view-sales-return-voucher"  component={ViewSalesReturnVoucher} />
             <Route exact path="/order/view-purchase-order-voucher"  component={ViewPurchaseOrderVoucher} />
             <Route exact path="/order/view-sales-order-voucher"  component={ViewSalesOrderVoucher} />
             <Route exact path="/order/quick-purchase-order-voucher/:id"  component={QuickPurchaseOrderVoucher} />
             <Route exact path="/order/quick-sales-order-voucher/:id"  component={QuickSalesOrderVoucher} />
             <Route exact path="/sales/quick-sales-return-voucher/:id"  component={QuickSalesReturnVoucher} />
             <Route exact path="/sales/quick-sales-voucher/:id"  component={QuickSalesVoucher} />
             <Route exact path="/purchase/quick-purchase-voucher/:id"  component={QuickPurchaseVoucher} />
             <Route exact path="/purchase/quick-purchase-return-voucher/:id"  component={QuickPurchaseReturnVoucher} />
             <Route exact path="/order/purchase-order-record"  component={PurchaseOrderRecord} />
             <Route exact path="/order/sales-order-record"  component={SalesOrderRecord} />
             <Route exact path="/purchase/purchase-record"  component={PurchaseRecord} />
             <Route exact path="/reports/purchase-record"  component={PurchaseRecord} />
             <Route exact path="/sales/sales-record"  component={SalesRecord} />
             <Route exact path="/reports/sales-record"  component={SalesRecord} />
             <Route exact path="/sales/sales-return-entry"  component={SalesReturnEntry} />
             <Route exact path="/reports/sales-return-record"  component={SalesReturnRecord} />


             <Route exact path="/service/service-entry"  component={ServiceEntry} />
             <Route exact path="/service/view-service-vouchers"  component={viewServiceVouchers} />
             <Route exact path="/service/quick-service-voucher/:id"  component={quickServiceVoucher} />
             <Route exact path="/service/service-update/:id"  component={ServiceEntry} />
             <Route exact path="/service/service-record"  component={ServiceRecord} />
             <Route exact path="/service/service-expense-record"  component={ServiceExpenseRecord} />


             <Route exact path="/service/service-expense-entry"  component={ServiceExpenseEntry} />
             <Route exact path="/service/service-expense-update/:id"  component={ServiceExpenseEntry} />
             <Route exact path="/service/view-service-expense-vouchers"  component={viewServiceExpenseVoucher} />
             <Route exact path="/service/quick-service-expense-voucher/:id"  component={quickServiceExpenseVoucher} />


             


             <Route exact path="/inventory/transfer-entry"  component={TransferEntry} />
             <Route exact path="/inventory/view-transfer-voucher"  component={ViewTransferVoucher} />
             <Route exact path="/inventory/quick-transfer-voucher/:id"  component={ViewQuickTransferVoucher} />
             <Route exact path="/inventory/transfer-record"  component={TransferRecord} />
             <Route exact path="/inventory/transfer-pending-record"  component={TransferPendingRecord} />
             <Route exact path="/inventory/transfer-receive-record"  component={TransferReceiveRecord} />
             <Route exact path="/inventory/transfer-update/:id"  component={TransferEntry} />
             
             {/* Purchase end */}
             <Route exact path="/settings/warehouse-manage"  component={warehousesManage} />
             <Route exact path="/documents/sms-sender"  component={smsSender} />
             <Route exact path="/documents/mobile-sms-sender"  component={mobileSmsSender} />
             <Route exact  path="/settings/user-manage"  component={UsersManage} />
             <Route exact path="/settings/branch-manage"  component={BranchesManage} />
             <Route exact path="/settings/institution-profile"  component={InstitutionProfile} />
             <Route exact path="/settings/unit-manage"  component={UnitManage} />
             <Route exact path="/settings/group-manage"  component={GroupManage} />
             <Route exact path="/settings/category-manage"  component={CategoryManage} />
             <Route exact path="/settings/item-manage"  component={ItemManage} />
             <Route exact path="/accounts/account-entry"  component={AccountManage} />
             <Route exact path="/accounts/location-manage"  component={LocationManage} />
             <Route exact path="/accounts/journal-entry"  component={JournalEntry} />
             <Route exact path="/accounts/expense-entry"  component={ExpenseEntry} />
             <Route exact path="/accounts/income-entry"  component={IncomeEntry} />
             <Route exact path="/accounts/contra-entry"  component={ContraEntry} />
             <Route exact path="/accounts/creditor-payment-entry"  component={CreditorPaymentEntry} />
             <Route exact path="/accounts/debitor-receipt-entry"  component={DebitorReceiptEntry} />
             <Route exact path="/reports/debtor-receipt-record"  component={DebtorReceiptRecord} />
             <Route exact path="/reports/creditor-payment-record"  component={CreditorPaymentRecord} />
             <Route exact path="/reports/expense-record"  component={ExpenseRecord} />
             <Route exact path="/reports/income-record"  component={IncomeRecord} />
             


             </Suspense>
          </main> 
          </div>
         </div>
         </Fragment>
            
          ):(   
          <div>
                <Suspense fallback={<b style={{textAlign:'center'}}>Loading...</b>}>
                <Route exact path="/" component={SignIn} />
                </Suspense>
                </div>
          )
            
            
           
        }
       
        </Router>
  );
}
const mapStateToPops = (state)=>{
  return {
    currentRoute:state.currentRouteReducer,
    authInfo:state.authInfoReducer
  }
}
export default connect(mapStateToPops,{currentRouteSet,authInfoSet})(App);